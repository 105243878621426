html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: sans-serif;
}

/* .cc-bottom-buttons-icon-container {
  height: 50px !important;
  width: 50px !important;
}

.cc-audio-icon-container {
  background-color: #0073ff !important;
  border-radius: 4px !important;
}

.cc-video-icon-container {
  background-color: #0073ff !important;
  border-radius: 4px !important;
}

.cc-screen-share-icon-container {
  background-color: #0073ff !important;
  border-radius: 4px !important;
}

.cc-end-call-icon-container {
  background-color: #ab0090 !important;
  border-radius: 4px !important;
  margin-left: 50px !important;
}

.cc-bottom-buttons.cc-bottom-buttons-container {
  gap: 25px !important;
}

.side-bar-main-user-video video {
  background-color: black !important;
  background-image: repeating-conic-gradient(#0073ff2a 0% 25%, #00000031 0% 50%) !important;;
  background-position: 0 0, 10px 10px !important;;
  background-size: 20px 20px !important;;
}

.cc-name-label {
  background-color: #0073ff !important;
}

.bottom-buttons-other-options {
  gap: 20px !important;
} */